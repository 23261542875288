import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import auth from './firebase/auth'
import { firestorePlugin } from 'vuefire'
import Buefy from 'buefy'

Vue.config.productionTip = false
Vue.use(firestorePlugin)
Vue.use(Buefy)

let app = null;
auth.onAuthStateChanged(user => {

  if (user == null) {
    launch(app);
  }else {
    // get the admin claim
    user.getIdTokenResult()
      .then(idTokenResult => {
        console.log(idTokenResult.claims.admin);
        user.admin = idTokenResult.claims.admin;

        // save our user to state
        store.commit('setUser', user);
        launch(app);
      });
  }
});

function launch(app) {
  // launch our app
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
  }
}
