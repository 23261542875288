import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue'),
  },
  // {
  //   path: '/mealplan/:id',
  //   name: 'MealPlan',
  //   component: () => import(/* webpackChunkName: "mealplan" */ '../views/MealPlan.vue'),
  //   props: true
  // },
  // {
  //   path: '/recipe/:id',
  //   name: 'Recipe',
  //   component: () => import(/* webpackChunkName: "recipe" */ '../views/Recipe.vue'),
  //   props: true
  // },
  // {
  //   path: '/shoppingList/',
  //   name: 'ShoppingList',
  //   component: () => import(/* webpackChunkName: "shoppinglist" */ '../views/ShoppingList.vue')
  // },
  /// ADMIN
  {
    path: '/admin/',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue'),
    children: [
      // Login
      {
        path: 'login',
        name: 'LoginView',
        component: () => import('../views/LoginView.vue'),
      },
      // Manage users etc
      {
        path: 'manage',
        name: 'ManageView',
        component: () => import('../views/ManageView.vue'),
      },
      // meal plans
      {
        path: 'mealplans',
        name: 'MealPlanListVIew',
        component: () => import('../views/MealPlanListView.vue'),
      },
      {
        path: 'mealplans/:id',
        name: 'MealPlanView',
        component: () => import( '../views/MealPlanView.vue'),
        props: true
      },
      // recipes
      {
        path: 'recipes',
        name: 'RecipeListView',
        component: () => import('../views/RecipeListView.vue'),
      },
      {
        path: 'recipes/:id',
        name: 'RecipeView',
        component: () => import('../views/RecipeView.vue'),
        props: true
      },
      // ingredients
      {
        path: 'ingredients',
        name: 'IngredientListView',
        component: () => import('../views/IngredientListView.vue'),
      },
      {
        path: 'ingredients/:id',
        name: 'IngredientView',
        component: () => import('../views/IngredientView.vue'),
        props: true
      },
      // preps
      {
        path: 'preps',
        name: 'PrepTermListView',
        component: () => import('../views/PrepTermListView.vue'),
      },
      {
        path: 'preps/:id',
        name: 'PrepTermView',
        component: () => import('../views/PrepTermView.vue'),
        props: true
      },
      // ingredient units
      {
        path: 'units',
        name: 'IngredientUnitListView',
        component: () => import('../views/IngredientUnitListView.vue'),
      },
      {
        path: 'units/:id',
        name: 'IngredientUnitView',
        component: () => import('../views/IngredientUnitView.vue'),
        props: true
      },
      // ingredient aisles
      {
        path: 'ingredientAisles',
        name: 'IngredientAisleListView',
        component: () => import('../views/IngredientAisleListView.vue'),
      },
      {
        path: 'ingredientAisles/:id',
        name: 'IngredientAisleView',
        component: () => import('../views/IngredientAisleView.vue'),
        props: true
      },
      // tips & encouragements
      {
        path: 'tipsEncouragements',
        name: 'TipsEncouragementListView',
        component: () => import('../views/TipsEncouragementListView.vue'),
      },
      {
        path: 'tipsEncouragements/:id',
        name: 'TipEncouragementView',
        component: () => import('../views/TipEncouragementView.vue'),
        props: true
      },
      {
        path: 'recipeCollections',
        name: 'RecipeCollectionListView',
        component: () => import('../views/RecipeCollectionListView.vue'),
      },
      {
        path: 'recipeCollections/:id',
        name: 'RecipeCollectionView',
        component: () => import('../views/RecipeCollectionView.vue'),
        props: true
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Authentication
router.beforeEach((to, from, next) => {
  // if we're not authenticated & admin
  if ((to.name != 'LoginView') && !(Store.state.isAuthenticated && Store.state.user.admin)) next({name: 'LoginView'})
  else next()
})

export default router
