import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    isAuthenticated: false
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      if (user != null) {
        state.isAuthenticated = Object.prototype.hasOwnProperty.call(user, "email");
      }else {
        state.isAuthenticated = false;
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
