<template>
  <div class="container" id="app">
    <b-navbar id="nav" class="pt-5 pb-2">
       <template #brand>
           <b-navbar-item tag="router-link" :to="{ path: '/admin' }">
               <img src="@/assets/logo.png" />
           </b-navbar-item>
       </template>
       <template #start>
           <b-navbar-item tag="router-link" :to="{ path: '/admin/mealplans' }">Meal Plans</b-navbar-item>
           <b-navbar-item tag="router-link" :to="{ path: '/admin/recipes' }">Recipes</b-navbar-item>
           <b-navbar-dropdown label="Collections">
            <b-navbar-item tag="router-link" :to="{ path: '/admin/recipeCollections' }">Recipe Collections</b-navbar-item>
           </b-navbar-dropdown>  
           <b-navbar-dropdown label="Recipe Elements">
             <b-navbar-item tag="router-link" :to="{ path: '/admin/ingredients' }">Ingredients</b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/admin/preps' }">Prep Terms</b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/admin/units' }">Ingredient Units</b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/admin/ingredientAisles' }">Ingredient Aisles</b-navbar-item>
           </b-navbar-dropdown>
           <b-navbar-item tag="router-link" :to="{ path: '/admin/tipsEncouragements' }">Tips</b-navbar-item>
       </template>
       <template v-if="isAuthenticated" #end>
         <b-navbar-item>
           <b-dropdown aria-role="list">
              <template #trigger>
                <a class="navbar-item"
                  role="button">
                  <b-icon icon="account"></b-icon>
                  <span class="ml-2">{{user.displayName}}</span>
                  <b-icon icon="menu-down"></b-icon>
                </a>
              </template>
              <b-dropdown-item aria-role="listitem" @click="logout">Logout</b-dropdown-item>
          </b-dropdown>
        </b-navbar-item>
       </template>
   </b-navbar>
   <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import auth from '@/firebase/auth'

export default {
  computed: mapState({
    // this is a helper from vuex
    user: state => state.user,
    isAuthenticated: state => state.isAuthenticated
  }),
  methods: {
    logout() {
      auth.signOut()
        .then(() => {
          this.$store.commit('setUser', null);
          this.$router.push({name: "LoginView"});
        });
    },
  }
};
</script>

<style>

html, body {
  background-color: #FFFFFF;
}

#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #333333;
  padding: 0 4%;
  width: 100%;
  margin: auto;
  background-color: #FFFFFF;
}

#nav {
  position: -webkit-sticky;  /* Safari */
  position: sticky;
  top: 0;
  background-color: #FFFFFF;
}

a {
  text-decoration: none;
}


</style>

<style lang="scss">

@import "assets/theme.scss";

</style>
